.plan {
  width: 100%;
  max-width: 812px;
  margin: 0 auto;
}

.plan h1 {
  font-size: 18px;
}

.plan__table {
  width: 100%;
  border-collapse: collapse;
}

.plan__table td {
  border: 1px solid black;
  box-sizing: border-box;
  padding: 5px;
  vertical-align: middle;
  background-color: white;
}

.plan__table td.plan__num {
  width: 46px;
  padding: 0;
  position: relative;
  text-align: center;
}

.plan__start-time {
  width: 65px;
  text-align: center;
}

.plan__timer-td {
  width: 20%;
}

.plan__table tr.plan__row_drop-under td {
  border-bottom: 2px solid red;
}


.plan__tomato-index {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.plan__done {
  position: absolute;
  left: -55px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 35px;
  opacity: 0;
}

.plan__tomato {
  position: absolute;
  left: -30px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 35px;
  opacity: 0;
}

.plan__table tr:hover .plan__tomato {
  opacity: 1;
}

.plan__tomato input {
  position: absolute;
  left: -99999px;
}

.plan__tomato input + b {
  width: 19px;
  height: 19px;
  display: inline-block;
  background-image: url(./images/tomato.png);
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.3;
}

.plan__tomato input:checked + b {
  opacity: 1;
}

.plan__table tr:hover .plan__done {
  opacity: 1;
}

.plan__row_done td {
  background-color: #DEF3D9;
}

td.plan__title {
  width: 618px;
  border-right: 0;
}

td.plan__timer {
  width: 81px;
  border-left: 0;
  font-size: 12px;
  position: relative;
}

.plan__timer-control,
.plan__table tr.plan__row_done.plan__row_process .plan__timer-control {
  opacity: 0;
}

.plan__table tr:hover .plan__timer-control,
.plan__table tr.plan__row_process .plan__timer-control {
  opacity: 1;
}

.plan__dragger {
  width: 20px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: -21px;
  user-select: none;
  background-image: url(./images/drag-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
}

.plan__table tr:hover .plan__dragger {
  opacity: 1;
}

.plan__row_drag {
  position: absolute;
  z-index: 100;
}