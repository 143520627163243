.timer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timer__control {
  background-position: center;
  background-repeat: no-repeat;
  /*background-color: rgba(0, 163, 199, 0.2);*/
  width: 30px;
  height: 30px;
  cursor: pointer; 
}

.timer__control_start {
  background-image: url(./images/start.svg);
}

.timer__control_pause {
  background-image: url(./images/pause.svg);
}