.plans-list__item {
  padding: 8px 0;
  box-sizing: border-box;
}

.plans-list__item a {
  color: black;
  text-decoration: none;
}


.plans-list__item a:hover {
  text-decoration: underline;
}