.app-content {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

.app-content__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back {
  color: gray;
  text-decoration: none;
  font-size: 14px;
}

.back:hover {
  text-decoration: underline;
}