.header {
  width: 100%;
  max-width: 1024px;
  background-color: gainsboro;
  margin: 0 auto;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.header-menu {
  display: block;
}

.header-menu__item {
  color: black;
}

.header-menu__item:hover {
  color: gray;
}